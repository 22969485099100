<template>
  <div class="table">
    <div class="table-title">
      <h1 v-text="$t(name)" class="text-subtitle"></h1>
    </div>
    <table-loader v-if="showLoading" />
    <div v-else-if="isEmpty" class="empty-chart">
      <div class="empty-chart__container">
        <img
          :src="require('@/assets/images/line-chart.svg')"
          class="empty-chart__icon"
        />
      </div>
      <span class="empty-chart__text">{{ emptyText }}</span>
    </div>
    <template v-else>
      <div class="table-content">
        <table class="stack">
          <thead>
            <tr>
              <th v-for="(header, index) in headers" v-bind:key="index">
                {{ getTranslatableString(header) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in rows" v-bind:key="index">
              <td v-for="(data, k) in row" v-bind:key="k">
                {{ getFormattedValue(data, k) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
    TableLoader: () => import('@/components/loaders/Table.vue'),
  },
  props: {
    translatable: {
      type: Boolean,
      default: false,
    },
    formats: {
      type: Array,
      default: () => [],
    },
    tableId: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    headers: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Array,
    },
  },
  computed: {
    ...mapState({
      loader: state => state.dashboard.loader || {},
    }),
    showLoading() {
      return this.loader[this.tableId];
    },
    isEmpty() {
      return this.rows.length === 0;
    },
    emptyText() {
      return this.$t('shared-components.table.empty');
    },
  },
  methods: {
    getTranslatableString(value) {
      return this.translatable ? this.$t(value) : value;
    },
    getFormattedValue(value, index) {
      if (this.formats[index] != null && this.formats[index] !== false) {
        return this.applyFormat(Number(value), this.formats[index]);
      }

      return value;
    },
    applyFormat(value, format) {
      switch (format) {
        case 'number':
          return this.$options.filters.numFormat(value);
        case 'time':
          return this.$moment.duration(value, 'seconds')
            .format('h[h] m[m] s[s]');
        default:
          return value;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_data-table.scss";
@import "~styles/components/_data-chart.scss";
</style>
